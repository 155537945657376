import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./ModalWallet.scss";
import coinbaseWallet from "../../../images/icon/coinbase-wallet.jpg";
import MetaMask from "../../../images/icon/MetaMask.png";
import WalletConnect from "../../../images/icon/WalletConnect.jpg";

const ModalWallet = (props) => {
  const connectWallet = [
    { name: "Rainbow", image: "" },
    { name: "Coinbase Wallet", image: coinbaseWallet },
    { name: "MetaMask", image: MetaMask },
    { name: "WalletConnect", image: WalletConnect },
  ];

  return (
    <>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        dialogClassName="modal-wallet modal-90w"
        // size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {/* Header  */}
        <Modal.Header closeButton>
          <a href="#">
            {" "}
            <svg
              fill="none"
              height="17"
              viewBox="0 0 11 17"
              width="11"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.99707 8.6543C0.99707 9.08496 1.15527 9.44531 1.51562 9.79688L8.16016 16.3096C8.43262 16.5732 8.74902 16.7051 9.13574 16.7051C9.90918 16.7051 10.5508 16.0811 10.5508 15.3076C10.5508 14.9121 10.3838 14.5605 10.0938 14.2705L4.30176 8.64551L10.0938 3.0293C10.3838 2.74805 10.5508 2.3877 10.5508 2.00098C10.5508 1.23633 9.90918 0.603516 9.13574 0.603516C8.74902 0.603516 8.43262 0.735352 8.16016 0.999023L1.51562 7.51172C1.15527 7.85449 1.00586 8.21484 0.99707 8.6543Z"
                fill="currentColor"
              ></path>
            </svg>{" "}
          </a>{" "}
          <Modal.Title>CONNECT A WALLET</Modal.Title>
        </Modal.Header>

        {/* BODY */}
        <Modal.Body>
          <div className="iekbcc0 ju367v6k _1vwt0cg2 ju367v75 ju367v7q">
            <div className="iekbcc0 ju367v3i ju367v43 ju367v2y ju367v4t">
              <div className="iekbcc0 ju367vgu ju367v11 ju367v14 ju367v1c ju367v2q">
                Popular
              </div>
            </div>
            <div className="iekbcc0 ju367va ju367v10 ju367v1i">
              {connectWallet.map((item, key) => (
                <button
                  className="connectWallet iekbcc0 iekbcc9 ju367v84 ju367v6d ju367v6y ju367v7j ju367vo ju367vt ju367vv ju367v8o ju367v99 ju367vav g5kl0l0 _12cbo8i3 ju367v8m _12cbo8i6"
                  data-testid="rk-wallet-option-rainbow"
                >
                  <div className="parent iekbcc0">
                    <img className="image-class" src={item.image} />
                    <div className="iekbcc0">{item.name}</div>
                  </div>
                </button>
              ))}
              {/* 
              <div className="iekbcc0 ju367va ju367v10">
                <button
                  className="iekbcc0 iekbcc9 ju367v84 ju367v6d ju367v6y ju367v7j ju367vo ju367vt ju367vv ju367v8o ju367v99 ju367vav g5kl0l0 _12cbo8i3 ju367v8m _12cbo8i6"
                  data-testid="rk-wallet-option-coinbase"
                >
                  <div className="iekbcc0 ju367vgo ju367v11 ju367v15 ju367v1c ju367v8o">
                    <div className="iekbcc0 ju367v4 ju367va ju367vz ju367v1n">
                      <div
                        className="iekbcc0 ju367vj ju367v26 ju367v67 ju367v8m ju367v8y"
                        role="img"
                      >
                        <div className="iekbcc0 ju367vh ju367v2h ju367v8k ju367v99"></div>
                        <div className="iekbcc0 ju367vc0 ju367vj ju367vt ju367vv ju367v2h ju367v8m ju367v99"></div>
                      </div>
                      <div className="iekbcc0">
                        <div className="iekbcc0">Coinbase Wallet</div>
                      </div>
                    </div>
                  </div>
                </button>
              </div>
              <div className="iekbcc0 ju367va ju367v10">
                <button
                  className="iekbcc0 iekbcc9 ju367v84 ju367v6d ju367v6y ju367v7j ju367vo ju367vt ju367vv ju367v8o ju367v99 ju367vav g5kl0l0 _12cbo8i3 ju367v8m _12cbo8i6"
                  data-testid="rk-wallet-option-metaMask"
                >
                  <div className="iekbcc0 ju367vgo ju367v11 ju367v15 ju367v1c ju367v8o">
                    <div className="iekbcc0 ju367v4 ju367va ju367vz ju367v1n">
                      <div
                        className="iekbcc0 ju367vj ju367v26 ju367v67 ju367v8m ju367v8y"
                        role="img"
                      >
                        <div className="iekbcc0 ju367vh ju367v2h ju367v8k ju367v99"></div>
                        <div className="iekbcc0 ju367vc0 ju367vj ju367vt ju367vv ju367v2h ju367v8m ju367v99"></div>
                      </div>
                      <div className="iekbcc0">
                        <div className="iekbcc0">MetaMask</div>
                      </div>
                    </div>
                  </div>
                </button>
              </div>
              <div className="iekbcc0 ju367va ju367v10">
                <button
                  className="iekbcc0 iekbcc9 ju367v84 ju367v6d ju367v6y ju367v7j ju367vo ju367vt ju367vv ju367v8o ju367v99 ju367vav g5kl0l0 _12cbo8i3 ju367v8m _12cbo8i6"
                  data-testid="rk-wallet-option-walletConnect"
                >
                  <div className="iekbcc0 ju367vgo ju367v11 ju367v15 ju367v1c ju367v8o">
                    <div className="iekbcc0 ju367v4 ju367va ju367vz ju367v1n">
                      <div
                        className="iekbcc0 ju367vj ju367v26 ju367v67 ju367v8m ju367v8y"
                        role="img"
                      >
                        <div className="iekbcc0 ju367vh ju367v2h ju367v8k ju367v99"></div>
                        <div className="iekbcc0 ju367vc0 ju367vj ju367vt ju367vv ju367v2h ju367v8m ju367v99"></div>
                      </div>
                      <div className="iekbcc0">
                        <div className="iekbcc0">WalletConnect</div>
                      </div>
                    </div>
                  </div>
                </button>
              </div> */}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="footer">
          <div className="iekbcc0 ju367vgu ju367v11 ju367v14 ju367v1a ju367v2q">
            New to Ethereum wallets?
          </div>
          <div className="iekbcc0 ju367veu ju367v11 ju367v14 ju367v1c ju367v2q">
            Learn More
          </div>

          {/* <Button variant="secondary" onClick={props.handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={props.handleClose}>
            Learn More
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalWallet;
