export const config = {
    saleAddress: "0x7E01191Bba9985fA47C3662783111BE0fD034458",
    price: 100,
    tokens: {
        BUSD: {
            address: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
            decimal: 18,
        },
        USDT: {
            address: "0x55d398326f99059fF775485246999027B3197955",
            decimal: 18,
        },
    },
};
