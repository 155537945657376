import { useEffect, useState } from "react";
import "./home.scss";
import discord from "../../images/icon/discord-2554b087.svg";
import instagram from "../../images/icon/instagram-268d2ee0.svg";
import linkedin from "../../images/icon/linkedin-e0a4e032.svg";
import medium from "../../images/icon/medium-4ce57938.svg";
import reddit from "../../images/icon/reddit-20de1eef.svg";
import telegram from "../../images/icon/telegram-b4a7f7ac.svg";
import twitter from "../../images/icon/twitter-46190d49.svg";

import { Button, Modal } from "react-bootstrap";
import ModalBuyNow from "./ModalBuyNow/ModalBuyNow";
import { config } from "../../config";
import {
    addressBalance,
    getRate,
    getStatus,
    hardCapValue,
} from "../../utility/sale";
import { useAddress, useSigner } from "@thirdweb-dev/react";
import { getTokenBalance } from "../../utility/token";
import Fade from "react-reveal/Fade";
import Countdown from "react-countdown";

const Home = () => {
    const [showBuyNow, setShowBuyNow] = useState(false);
    const [price, setPrice] = useState(0);

    const handleCloseBuyNow = () => setShowBuyNow(false);
    const handleShowBuyNow = () => setShowBuyNow(true);

    const SocialIcon = [
        //   { link: "discord", image: discord },
        //   { link: "instagram", image: instagram },
        //   { link: "medium", image: medium },
        //   { link: "reddit", image: reddit },
        { link: "https://t.me/infotradex24", image: telegram },
        { link: "https://x.com/helptradex24?s=21", image: twitter },
        //   { link: "linkedin", image: linkedin },
    ];
    const address = useAddress();
    const signer = useSigner();

    const [saleActive, setSaleActive] = useState(false);
    const [totalValue, setTotalValue] = useState(0);
    const [contractBalance, setContractBalance] = useState(0);

    useEffect(() => {
        if (!address || !signer) return;
        const getData = async () => {
            let data = await getStatus(config.saleAddress, signer);
            setSaleActive(data);
        };
        getData();
    }, [address, signer]);

    useEffect(() => {
        if (!address || !signer) return;
        const getData = async () => {
            let data = await getRate(config.saleAddress, signer);
            setPrice(data);
        };
        getData();
    }, [address, signer]);

    useEffect(() => {
        if (!address || !signer) return;
        const getData = async () => {
            let data = await hardCapValue(config.saleAddress, signer);
            setTotalValue(parseFloat(data) / 100);
        };
        getData();
    }, [address, signer]);

    useEffect(() => {
        if (!address || !signer) return;
        const getData = async () => {
            let getTokenBal = await getTokenBalance(
                config.saleAddress,
                "USDT",
                signer,
            );
            let getTokenBalance1 = await getTokenBalance(
                config.saleAddress,
                "BUSD",
                signer,
            );

            setContractBalance(
                (parseFloat(getTokenBal) + parseFloat(getTokenBalance1)) * 20,
            );
        };
        getData();
    }, [address, signer]);
    const Timer = ({ days, hours, minutes, seconds, completed }) => {
        return (
            <Fade top>
                <div className="mb-20 d-flex justify-content-center">
                    <div font="orbitron" className="sc-jSMdHm fOxTEo">
                        <div className="count-item">
                            <span className="count">{days}</span>
                            <span className="label">d</span>
                        </div>
                        <div className="count-item">
                            <span className="count">{hours}</span>
                            <span className="label">h</span>
                        </div>
                        <div className="count-item">
                            <span className="count">{minutes}</span>
                            <span className="label">m</span>
                        </div>
                        <div className="count-item">
                            <span className="count">{seconds}</span>
                            <span className="label">s</span>
                        </div>
                    </div>
                </div>
            </Fade>
        );
    };
    return (
        <div className="sc-eCYdKt cNTngN">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="mb-40 text-center">
                            <Fade top>
                                <div className="mb-20">
                                    <h5 className="ff-outfit fw-600 text-white text-uppercase">
                                        Pre-Sale Ends in{" "}
                                    </h5>
                                </div>
                            </Fade>
                            <Countdown date={1699036200000} renderer={Timer} />
                            <Fade top>
                                <div className="mb-20">
                                    <h1 className="banner-title">
                                        Unified Decentralized
                                        <br />
                                        Platform
                                    </h1>
                                </div>
                            </Fade>
                            <Fade top>
                                <h5 className="ff-outfit text-white">
                                    Buy tokens now and reap the benefits of the
                                    blockchain revolution!
                                </h5>
                            </Fade>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <div className="mb-2 d-flex align-items-center justify-content-between gap-1 flex-wrap">
                            <Fade top>
                                <h5 className="ff-orbitron fs-15 fw-600 text-white text-uppercase">
                                    Stage 1 :
                                </h5>
                                <h5 className="ff-orbitron fs-15 fw-600 text-white text-uppercase">
                                    {/* $ {contractBalance} / {totalValue} */}${" "}
                                    {totalValue} / {totalValue}
                                </h5>
                            </Fade>
                        </div>
                        <Fade top>
                            <div className="mb-30">
                                <div
                                    variant="dashed"
                                    className="sc-gKXNOb cAlltz"
                                >
                                    <div className="progress-done">
                                        <p>
                                            {(
                                                (totalValue * 100) /
                                                totalValue
                                            ).toFixed(2)}
                                            {/* {(
                                                (contractBalance * 100) /
                                                totalValue
                                            ).toFixed(4)} */}
                                            %
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Fade>
                        <Fade top>
                            <div className="mb-30 text-center">
                                <p className="ff-orbitron fs-15 fw-600 text-white text-uppercase">
                                    1 TRDC = {(1 / price).toFixed(4)} USD
                                </p>
                                {/* <p className="ff-orbitron fs-15 fw-600 text-white text-uppercase">
                                NEXT STAGE PRICE = 0.002 ETH
                            </p> */}
                            </div>
                        </Fade>
                        <div className="mb-10 d-flex align-items-center justify-content-center">
                            <Fade top>
                                <p style={{ color: "red" }}>
                                    Note : Please use BEP20 USDT
                                </p>
                            </Fade>
                        </div>

                        <div className="mb-30 d-flex align-items-center justify-content-center">
                            <Fade top>
                                <button
                                    onClick={handleShowBuyNow}
                                    variant="gradient"
                                    className="sc-dkzCjb iAqwGG"
                                    disabled={saleActive}
                                >
                                    Buy now
                                </button>
                            </Fade>

                            <ModalBuyNow
                                show={showBuyNow}
                                handleClose={handleCloseBuyNow}
                                contractBalance={contractBalance}
                            />
                        </div>
                        <div className="mb-74 d-flex align-items-center justify-content-center">
                            <Fade top>
                                <a href="https://trdc.io/" target="blank">
                                    <button
                                        // onClick={handleShowBuyNow}
                                        variant="gradient"
                                        className="sc-dkzCjb iAqwGG"
                                        // disabled={saleActive}
                                    >
                                        See More
                                    </button>
                                </a>
                            </Fade>
                        </div>
                        <ul className="social-links">
                            {SocialIcon.map((item, key) => (
                                <Fade top>
                                    <li key={key}>
                                        <a
                                            href={item.link}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <img src={item.image} alt="icon" />
                                        </a>
                                    </li>
                                </Fade>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
