import { ethers } from "ethers";
import { ABI_PRE_SALE } from "./contracts/saleABI";
import { config } from "../config";

export const getStatus = (saleAddress, signer) => {
    return new Promise(async (resolve, reject) => {
        const contract = new ethers.Contract(saleAddress, ABI_PRE_SALE, signer);
        const data = await contract.presaleOver();
        resolve(data);
    });
};

export const hardCapValue = (saleAddress, signer) => {
    return new Promise(async (resolve, reject) => {
        const contract = new ethers.Contract(saleAddress, ABI_PRE_SALE, signer);
        const data = await contract.hardcap();
        const formattedData = ethers.utils.formatUnits(data, 18);
        resolve(formattedData);
    });
};

export const addressBalance = (saleAddress, signer) => {
    return new Promise(async (resolve, reject) => {
        const provider = signer.provider;
        const data = await provider.getBalance(saleAddress);
        const formattedData = ethers.utils.formatUnits(data, 18);
        resolve(formattedData);
    });
};

export const userClaimableToken = (saleAddress, signer, address) => {
    return new Promise(async (resolve, reject) => {
        const contract = new ethers.Contract(saleAddress, ABI_PRE_SALE, signer);
        const data = await contract.claimable(address);
        const formattedData = ethers.utils.formatUnits(data, 18);
        resolve(formattedData);
    });
};

export const getMinAmount = (saleAddress, signer) => {
    return new Promise(async (resolve, reject) => {
        const contract = new ethers.Contract(saleAddress, ABI_PRE_SALE, signer);
        const data = await contract.minAmount();
        const formattedData = ethers.utils.formatUnits(data, 18);
        resolve(formattedData);
    });
};

export const getMaxAmount = (saleAddress, signer) => {
    return new Promise(async (resolve, reject) => {
        const contract = new ethers.Contract(saleAddress, ABI_PRE_SALE, signer);
        const data = await contract.maxAmount();
        const formattedData = ethers.utils.formatUnits(data, 18);
        resolve(formattedData);
    });
};

export const getRate = (saleAddress, signer) => {
    return new Promise(async (resolve, reject) => {
        const contract = new ethers.Contract(saleAddress, ABI_PRE_SALE, signer);
        const rate = await contract.rate();
        const decimalFactor = await contract.decimalFactor();
        resolve(parseFloat(rate) / parseFloat(decimalFactor));
    });
};

export const depositTokenFun = (token, saleAddress, signer, amount) => {
    return new Promise(async (resolve, reject) => {
        try {
            const contract = new ethers.Contract(
                saleAddress,
                ABI_PRE_SALE,
                signer,
            );
            const value = ethers.utils.parseEther(amount);
            let result;
            await contract
                .buyToken(config.tokens[token].address, value)
                .then((r) => {
                    result = r;
                    console.log("%c Line:62 🍔 r", "color:#4fff4B", result);
                })
                .catch((err) => {
                    console.log("%c Line:67 🌶 err", "color:#e41a6a", err);
                });
            await result.wait();
            resolve(result);
        } catch (error) {
            console.log("%c Line:62 🍬 error", "color:#465975", error);
            resolve(false);
        }
    });
};
