import Modal from "react-bootstrap/Modal";
import "./ModalBuyNow.scss";
import { useEffect, useState } from "react";
import {
    addressBalance,
    getMaxAmount,
    getMinAmount,
    getStatus,
    hardCapValue,
    userClaimableToken,
    depositTokenFun,
    getRate,
} from "../../../utility/sale";
import { useAddress, useSigner } from "@thirdweb-dev/react";
import { toast } from "react-toastify";
import { config } from "../../../config";
import {
    approveToken,
    getTokenAllowance,
    getTokenBalance,
} from "../../../utility/token";
import Fade from "react-reveal/Fade";
import usdtImg from "../../../images/icon/coin3.png";
import busdImg from "../../../images/icon/binance-usd-busd-logo.png";
import { MenuItem, OutlinedInput, Select } from "@material-ui/core";
const ModalBuyNow = (props) => {
    // const [show, setShow] = useState(false);

    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);
    const address = useAddress();
    const signer = useSigner();
    const setAmountData = async (e) => {
        setAmount(e.target.value);
        setGetAmount(e.target.value * price);
    };
    const [price, setPrice] = useState(0);

    const [amount, setAmount] = useState(0);
    const [getAmount, setGetAmount] = useState(0);
    const [selectedToken, setSelectedToken] = useState("USDT");
    const [selectedTokenBalance, setSelectedTokenBalance] = useState("USDT");
    const [saleActive, setSaleActive] = useState(false);
    const [totalValue, setTotalValue] = useState(0);
    const [contractBalance, setContractBalance] = useState(0);
    const [claimable, setClaimable] = useState(0);
    const [approvedBalance, setApprovedBalance] = useState(0);
    const [minAmount, setMinAmount] = useState(0);
    const [maxAmount, setMaxAmount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState("USDT");
    useEffect(() => {
        if (!address || !signer) return;
        const getData = async () => {
            let data = await getMinAmount(config.saleAddress, signer);
            setMinAmount(data);
        };
        getData();
    }, [address, signer, loading]);

    useEffect(() => {
        if (!address || !signer) return;
        const getData = async () => {
            let data = await getRate(config.saleAddress, signer);
            setPrice(data);
        };
        getData();
    }, [address, signer]);

    useEffect(() => {
        if (!address || !signer) return;
        const getData = async () => {
            let data = await getMaxAmount(config.saleAddress, signer);
            setMaxAmount(parseFloat(data));
        };
        getData();
    }, [address, signer, loading]);

    useEffect(() => {
        if (!address || !signer) return;
        const getData = async () => {
            let getTokenBal = await getTokenBalance(
                address,
                selectedToken,
                signer,
            );
            setContractBalance(parseFloat(getTokenBal));
        };
        getData();
    }, [address, signer, selectedToken]);

    useEffect(() => {
        if (!address || !signer) return;
        const getData = async () => {
            let data = await userClaimableToken(
                config.saleAddress,
                signer,
                address,
            );
            setClaimable(parseFloat(data));
        };
        getData();
    }, [address, signer, loading]);

    useEffect(() => {
        if (!address || !signer) return;
        const getData = async () => {
            let data = await getTokenAllowance(
                address,
                selectedToken,
                config.saleAddress,
                signer,
            );
            console.log("%c Line:100 🍖 data", "color:#e41a6a", data);
            setApprovedBalance(parseFloat(data));
        };
        getData();
    }, [address, signer, loading]);

    const allowToken = async () => {
        // e.approveToken();
        setLoading(true);

        let data = await approveToken(
            address,
            selectedToken,
            config.saleAddress,
            signer,
        );
        console.log("%c Line:131 🍻 data", "color:#465975", data);
        if (!data) {
            toast.error(`Error While Transaction`);
            setLoading(false);
            return;
        }
        toast.success(`approved successfully`);
        setLoading(false);
    };

    const depositToken = async () => {
        // e.approveToken();

        setLoading(true);
        if (amount < parseInt(minAmount)) {
            toast.error(`Enter min amount ${minAmount}`);
            setLoading(false);
            return;
        }
        let max = maxAmount - claimable;
        // if (amount >= max) {
        //     toast.error(`Enter Max amount ${max}`);
        //     setLoading(false);
        //     return;
        // }
        let data = await depositTokenFun(
            selectedToken,
            config.saleAddress,
            signer,
            amount,
        );
        if (!data) {
            toast.error(`Error While Transaction`);
            setLoading(false);
            return;
        }
        toast.success(`Transaction Complete`);
        setAmount(0);
        setLoading(false);
    };

    return (
        <>
            {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}

            <Modal
                show={props.show}
                onHide={props.handleClose}
                dialogClassName="modal-buynow modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title>BE AN EARLY INVESTOR</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div class="gittu-modal-body">
                        <div class="mb-20">
                            <h5 class="ff-outfit fw-600 text-white text-uppercase">
                                Balance : {contractBalance} {selectedToken}
                            </h5>
                        </div>
                        <div class="presale-item mb-25">
                            <Fade bottom>
                                <h6>Amount</h6>
                            </Fade>

                            <div class="input-group">
                                <input
                                    type="number"
                                    min="0.001"
                                    step="0.001"
                                    name="payment-amount"
                                    id="payment-amount"
                                    placeholder="0.5"
                                    value={amount}
                                    onChange={(e) => {
                                        setAmountData(e);
                                    }}
                                />
                                <div class="input-group-dropdown">
                                    <div class="sc-iBkiHk efIDXs">
                                        <button class="dropdown-toggle">
                                            {/* <img
                        src={value === "USDT" ? usdtImg : busdImg}
                        alt="icon"
                        height={"40"}
                        width={"40"}
                      /> */}
                                            <Select
                                                value={value}
                                                onChange={(e) =>
                                                    setValue(e.target.value)
                                                }
                                                input={
                                                    <OutlinedInput label="Tag" />
                                                }
                                                style={{ color: "#fff" }}
                                            >
                                                <MenuItem value="USDT">
                                                    {" "}
                                                    <img
                                                        src={usdtImg}
                                                        alt="icon"
                                                        height={"40"}
                                                        width={"40"}
                                                    />{" "}
                                                    &nbsp; USDT
                                                </MenuItem>
                                                <MenuItem value="BUSD">
                                                    {" "}
                                                    <img
                                                        src={busdImg}
                                                        alt="icon"
                                                        height={"40"}
                                                        width={"40"}
                                                    />{" "}
                                                    &nbsp; BUSD
                                                </MenuItem>
                                            </Select>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="presale-item mb-25">
                            <Fade bottom>
                                <h6>Get Amount ( TRDC )</h6>
                            </Fade>
                            <input
                                type="text"
                                name="usd-amount"
                                id="usd-amount"
                                placeholder="569633"
                                disabled={true}
                                value={getAmount}
                            />
                        </div>
                        <ul class="token-info-list mb-35">
                            <li>
                                <Fade bottom>
                                    <p>$ Price</p>
                                </Fade>
                                <Fade bottom>
                                    <p>{(1 / price).toFixed(4)}</p>
                                </Fade>
                            </li>
                            {/* <li>
                                <p>Bonus 0%</p>
                                <p>0</p>
                            </li> */}
                            {/* <li> 
                                <p>Total Amount</p>
                                <p>1000</p>
                            </li> */}
                        </ul>

                        {loading ? (
                            <button
                                variant="gradient"
                                class="sc-dkzCjb jyEwHO btn-approve"
                                // onClick={allowToken}
                                disabled={true}
                            >
                                Loading...
                            </button>
                        ) : approvedBalance == 0 || approvedBalance < amount ? (
                            <button
                                variant="gradient"
                                class="sc-dkzCjb jyEwHO btn-approve"
                                onClick={allowToken}
                                // disabled={true}
                            >
                                Approve
                            </button>
                        ) : (
                            <button
                                variant="gradient"
                                class="sc-dkzCjb jyEwHO btn-approve"
                                onClick={depositToken}
                                // disabled={true}
                            >
                                Buy Now
                            </button>
                        )}
                    </div>
                </Modal.Body>
                {/* <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={props.handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
            </Modal>
        </>
    );
};

export default ModalBuyNow;
