import React, { useEffect, useState } from "react";
import logo from "../../images/logo/logo.png";
import ModalWallet from "./ModalWallet/ModalWallet";
import "./header.scss";

import Drawer from "@material-ui/core/Drawer";

import discord from "../../images/icon/discord-2554b087.svg";
import telegram from "../../images/icon/telegram-b4a7f7ac.svg";
import twitter from "../../images/icon/twitter-46190d49.svg";
import { ConnectWallet, trustWallet } from "@thirdweb-dev/react";
import Fade from "react-reveal/Fade";

const Header = () => {
    const [showWallet, setShowWallet] = useState(false);

    const handleCloseWallet = () => setShowWallet(false);
    const handleShowWallet = () => setShowWallet(true);

    const SocialIcon = [
        { link: "https://t.me/infotradex24", image: telegram },
        { link: "https://x.com/helptradex24?s=21", image: twitter },
    ];

    const menuLinks = [
        // { link: "", name: "Demo one" },
        // { link: "", name: "Demo two" },
        // { link: "", name: "Demo three" },
        // { link: "", name: "Demo four" },
    ];

    // Drawer
    // const [state, setDrawer] = React.useState({
    //   top: false,
    //   // left: false,
    //   // bottom: false,
    //   // right: false,
    // });
    const [isDrawer, setDrawer] = React.useState(false);

    useEffect(() => {
        if (showWallet === true) {
            setDrawer(false);
        }
    }, [showWallet]);

    const OnClickConnectWallet = () => {
        toggleDrawer("left", false);
        handleShowWallet();
    };
    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        setDrawer({ ...isDrawer, [anchor]: open });
    };

    // Mobile Drawer Content

    const DrawerContent = () => {
        return (
            <div className="gittu-mobile-menu-content">
                <div className="mobile-menu-top">
                    <Fade top>
                        <a className="mobile-logo" href="/">
                            <img src={logo} alt="Logo" />
                        </a>
                    </Fade>
                    <button
                        onClick={toggleDrawer("left", false)}
                        className="mobile-menu-close"
                    >
                        <svg
                            stroke="currentColor"
                            fill="currentColor"
                            stroke-width="0"
                            viewBox="0 0 1024 1024"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                        </svg>
                    </button>
                </div>

                <ul className="mobile-menu-list mb-40">
                    {menuLinks.map((item, key) => (
                        <li key={key}>
                            <a href={item.link}>{item.name}</a>
                        </li>
                    ))}
                </ul>
                <ul className="mobile-social-links mb-40">
                    {SocialIcon.map((item, key) => (
                        <li>
                            <a
                                href={item.link}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img src={item.image} alt={item.link} />
                            </a>
                        </li>
                    ))}
                </ul>
                <ul className="mobile-menu-list mb-40">
                    <li>
                        <a
                            href="https://trdc.io/wp-content/uploads/2022/07/TRDC-Lite-Paper.pdf.pdf"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Whitepaper
                        </a>
                    </li>
                </ul>
                <div className="d-flex justify-content-center">
                    <ConnectWallet />
                    {/* <button
            onClick={OnClickConnectWallet}
            className="sc-dkzCjb dGfbQV connect-wallet-btn"
            variant="connect"
          >
            Connect <span>Wallet</span>
          </button> */}
                </div>
            </div>
        );
    };

    // Header return
    return (
        <>
            <div className="sc-bczSft fekrrr header-section">
                <div className="container">
                    <div className="gittu-header-content">
                        <div className="gittu-header-left">
                            <Fade left>
                                <a className="gittu-header-logo" href="/">
                                    <img src={logo} alt="Logo" />
                                </a>
                            </Fade>
                            <div className="sc-hKMtDE deGHTS">
                                {/* <Fade left>
                                    <button className="demo-btn">
                                        <svg
                                            stroke="currentColor"
                                            fill="currentColor"
                                            stroke-width="0"
                                            viewBox="0 0 16 16"
                                            height="1em"
                                            width="1em"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zM2.5 2a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zM1 10.5A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z"></path>
                                        </svg>
                                    </button>
                                </Fade> */}
                                <ul className="dropdown-demo-list">
                                    {menuLinks.map((item, key) => (
                                        <li key={key}>
                                            <a href={item.link}>{item.name}</a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="gittu-header-right">
                            <div className="gittu-header-right-menu">
                                <ul className="gittu-header-menu">
                                    <Fade right>
                                        <li>
                                            <a
                                                href="https://trdc.io/wp-content/uploads/2022/07/TRDC-Lite-Paper.pdf.pdf"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                Whitepaper
                                            </a>
                                        </li>
                                    </Fade>
                                </ul>
                                {/* <button
                  onClick={handleShowWallet}
                  className="sc-dkzCjb dGfbQV connect-wallet-btn"
                  variant="connect"
                >
                  Connect <span>Wallet</span>
                </button> */}
                                <Fade right>
                                    <ConnectWallet />
                                </Fade>
                                <ModalWallet
                                    show={showWallet}
                                    handleClose={handleCloseWallet}
                                />

                                {/* Mobile Toggle Button show under 991px */}
                                <div className="gittu-header-menu-toggle">
                                    <button
                                        onClick={toggleDrawer("left", true)}
                                        className="menu-toggler"
                                    >
                                        <svg
                                            stroke="currentColor"
                                            fill="currentColor"
                                            stroke-width="0"
                                            viewBox="0 0 20 20"
                                            aria-hidden="true"
                                            height="1em"
                                            width="1em"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {["left"].map((anchor) => (
                <Drawer
                    anchor={anchor}
                    open={isDrawer[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                >
                    <DrawerContent />
                </Drawer>
            ))}
        </>
    );
};

export default Header;
