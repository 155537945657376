import logo from "./logo.svg";
import "./App.scss";
import Header from "./componet/header/Header";
import Home from "./screen/home/Home";
import {
    ThirdwebProvider,
    coinbaseWallet,
    metamaskWallet,
    trustWallet,
    walletConnect,
} from "@thirdweb-dev/react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Binance } from "@thirdweb-dev/chains";

function App() {
    return (
        <ThirdwebProvider
            activeChain="binance"
            supportedChains={[Binance]}
            supportedWallets={[
                trustWallet({
                    chains: [Binance],
                }),
                metamaskWallet(),
                coinbaseWallet(),
                walletConnect(),
            ]}
        >
            <div className="App">
                <Header />
                <Home />
            </div>
            <ToastContainer />
        </ThirdwebProvider>
    );
}

export default App;
